const TAG_PLACEHOLDER = '\t';

export default function highlightSubterms(subterms, highlightingTag) {
  const replacementTerm = `<${TAG_PLACEHOLDER}>$&</${TAG_PLACEHOLDER}>`;
  const replacements = subterms.map((part) => [new RegExp(part, 'gi'), replacementTerm]);
  const placeholder = new RegExp(TAG_PLACEHOLDER, 'g');
  return function highlighter(input) {
    let highlightedText = input.replace(placeholder, ' ');
    for (let i = 0; i < replacements.length; i += 1) {
      const [part, replacement] = replacements[i];
      highlightedText = highlightedText.replace(part, replacement);
    }
    highlightedText = highlightedText.replace(placeholder, highlightingTag);
    return highlightedText;
  };
}
