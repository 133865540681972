<template>
  <div
    v-click-away="hideSuggestions"
    class="tsg-search-form"
    :class="containerClass"
  >
    <div class="tsg-search-form__content">
      <form
        class="tsg-search-form__form"
        @submit.prevent="submitAndClose"
      >
        <ListDropdown
          ref="categoryDropdown"
          v-model="fields.values.categoryPath"
          :label-on-selection="true"
          placeholder="Alle Kategorien"
          :options="sanitizedProductCategories"
          include-blank="Alle Kategorien"
          class="grid__item grid__item--6 grid__item--m-6 grid__item--l-3"
          variant="search"
          @open="onOpenCategoryDropdown"
          @close="onCloseCategoryDropdown"
          @change="onSelectCategory"
        />
        <input
          ref="primaryInput"
          v-model.trim="fields.values.term"
          tabindex="0"
          spellcheck="false"
          :placeholder="placeholderSearchTerm"
          type="search"
          class="tsg-search-form__input input input--borderless"
          @input="onInputTerm"
          @keydown.esc.prevent="hideSuggestions"
          @keydown.up.prevent="selectPreviousSuggestion"
          @keydown.down.prevent="selectNextSuggestion"
          @focus="onFocusInput"
        />
        <button
          type="submit"
          tabindex="0"
          class="tsg-search-form__btn tsg-search-form__btn--submit"
        >
          <SvgIcon
            class="icon tsg-search-form__icon"
            :image="searchIcon"
          />
        </button>
      </form>
    </div>

    <div
      v-if="showSuggestions || showLoadingSpinner"
      class="tsg-search-form__suggestions grid grid--m-gutter-small grid--l-gutter-default"
    >
      <template v-if="hasValidTerm">
        <div
          v-if="showLoadingSpinner"
          class="tsg-search-form__suggestions-hint grid__item grid__item--12 text--center"
        >
          Lade…
        </div>
        <template v-else>
          <ul
            v-if="hasSuggestions"
            class="tsg-search-form__suggestions-list"
          >
            <li
              v-for="(suggestion, index) in suggestions"
              :key="index"
              class="tsg-search-form__suggestions-list-item"
              :class="suggestionItemClasses(index)"
            >
              <a
                v-if="suggestion.type !== 'internal'"
                class="tsg-search-form__suggestions-list-link"
                :href="suggestion.href"
                target="_blank"
                rel="noopener"
                @click.prevent="selectAndFollowSuggestion(index)"
                @mouseover="selectSuggestion(index)"
                @mouseout="unselectSuggestion"
                @focus="selectSuggestion(index)"
                v-html="suggestion.highlightedLabel"
              />
              <nuxt-link
                v-else-if="suggestion.type === 'internal'"
                class="tsg-search-form__suggestions-list-link"
                :to="suggestion.to"
                @focus="selectSuggestion(index)"
                @mouseout="unselectSuggestion"
                @mouseover="selectSuggestion(index)"
                @click.prevent="selectAndFollowSuggestion(index)"
                v-html="suggestion.highlightedLabel"
              />
            </li>
          </ul>
          <div
            v-else
            class="tsg-search-form__suggestions-hint grid__item grid__item--12 text--center"
          >
            Es wurden keine passenden Produkte gefunden.
          </div>
        </template>
      </template>
      <div
        v-else
        class="tsg-search-form__suggestions-hint grid__item grid__item--12 text--center"
      >
        Geben Sie mindestens {{ minTermLength }} Zeichen ein.
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash-es/debounce';
import inRange from 'lodash-es/inRange';
import { mixin as Clickaway } from 'vue3-click-away';
import { parseFilterParams } from '@/lib/filter-params';
import closeIcon from '@/assets/03-generic/images/menu-close.svg';
import Form from '@/mixins/form';
import Types from '@/mixins/form/types';
import highlightSubterms from '@/lib/subterm-highlighter';
import searchIcon from '@/assets/03-generic/images/search.svg';
import { assessLink } from '@/lib/link-assessor';
import ListDropdown from '@/components/list-dropdown.vue';
import { mapGetters } from 'vuex';

const MIN_TERM_LENGTH = 3;

function highlightSuggestions(suggestions, query) {
  const queryParts = query.trim().split(/  */);
  const highlight = highlightSubterms(queryParts, 'em');
  for (let i = 0; i < suggestions.length; i += 1) {
    const suggestion = suggestions[i];
    const { label } = suggestion;
    suggestion.highlightedLabel = highlight(label);
    Object.apply(suggestion, assessLink(suggestion.href));
  }
  return suggestions;
}

function getCategoryPathFromRoute(route) {
  const filterParams = parseFilterParams(route);

  const paths = (filterParams.filter || {}).productCategoryPaths || [];
  if (paths.length === 0) return null;

  const firstPath = paths[0];
  if (!firstPath) return null;

  // This gets the top level category path, which is totally fine here as this
  // particular app does not support selection of multiple categories at once
  // (whereas Goliath would generally support it).
  return firstPath.split('/')[0];
}

function getSearchTermFromRoute(route) {
  if (!route.query.search) return '';
  return route.query.search || '';
}

let controller;

export default {
  name: 'TsgSearchForm',
  components: {
    ListDropdown,
  },
  mixins: [
    Form({
      categoryPath: {
        type: Types.String,
      },
      term: {
        type: Types.String,
        validate(term) {
          // Only allow form submission when no string given or it at least 3
          // characters long.
          const isValid = !term || term.length >= 3;
          return { isValid, message: 'Search term too short' };
        },
      },
    }),
    Clickaway,
  ],
  data() {
    return {
      selectedIndex: -1,
      termChanged: false,
      isLoadingSuggestions: false,
      selectedCategory: null,
      minTermLength: MIN_TERM_LENGTH,
      suggestions: [],
      suggestionsVisible: true,
      searchString: null,
      searchCategory: null,
      searchIcon,
      closeIcon,
      debouncedSearch: debounce((searchString) => {
        // Do not trigger search when string is same.
        if (
          this.searchString === searchString.term &&
          this.searchCategory === searchString.categoryPath
        ) {
          this.isLoadingSuggestions = false;
          return;
        }

        // Abort any ongoing fetch
        if (controller) {
          controller.abort('Cancel previous search');
        }

        controller = new AbortController();

        $fetch('/api/suggestions', {
          signal: controller.signal,
          method: 'POST',
          body: {
            searchTerm: searchString.term.replace(/[^-+a-zA-Z0-9 äüößÄÜÖ]/g, '').trim(),
            categoryPath: searchString.categoryPath,
          },
        })
          .then((suggestions) => {
            this.suggestions = highlightSuggestions(suggestions, searchString.term);

            this.searchString = searchString.term;
            this.searchCategory = searchString.categoryPath;
            this.isLoadingSuggestions = false;
            controller = null;
          })
          .catch((e) => {
            if (e.message.includes('The user aborted a request')) {
              return;
            }
            controller = null;
            this.isLoadingSuggestions = false;
            console.error(e);
          });
      }, 400),
    };
  },
  computed: {
    ...mapGetters('navigation', ['productCategories']),
    sanitizedProductCategories() {
      return this.productCategories.map((item) => ({
        label: item.name,
        value: item.path,
      }));
    },
    hasTerm() {
      return !!this.fields.values.term;
    },
    hasValidTerm() {
      return this.hasTerm && this.fields.values.term.length >= this.minTermLength;
    },
    showSuggestions() {
      return this.suggestionsVisible && this.hasTerm && this.searchString;
    },
    hasSuggestions() {
      return this.suggestions.length > 0;
    },
    showLoadingSpinner() {
      return this.isLoadingSuggestions && !this.hasSuggestions;
    },
    selectedSuggestion() {
      if (!this.hasSelectedSuggestion) return undefined;
      return this.suggestions[this.selectedIndex];
    },
    hasSelectedSuggestion() {
      return inRange(this.selectedIndex, 0, this.suggestions.length);
    },
    firstSuggestionSelected() {
      return this.selectedIndex === 0;
    },
    lastSuggestionSelected() {
      return this.selectedIndex === this.lastSuggestionIndex;
    },
    lastSuggestionIndex() {
      return this.suggestions.length - 1;
    },
    placeholderSearchTerm() {
      return 'Suche nach...';
    },
    containerClass() {
      return {
        'tsg-search-form--has-suggestions': this.showSuggestions,
      };
    },
  },
  watch: {
    $route(route) {
      this.fields.values.term = getSearchTermFromRoute(route);
      this.fields.values.categoryPath = getCategoryPathFromRoute(route);
    },
  },
  mounted() {
    this.fields.values.term = getSearchTermFromRoute(this.$route);
    this.fields.values.categoryPath = getCategoryPathFromRoute(this.$route);
  },
  methods: {
    onFocusInput() {
      this.suggestionsVisible = true;
    },
    async onSelectCategory(categoryPath) {
      this.$refs.categoryDropdown.focus();
      this.suggestionsVisible = true;
      if (categoryPath === this.fields.values.categoryPath || !this.hasValidTerm) return;

      await this.populateSuggestions();
    },
    async onInputTerm(term) {
      this.suggestionsVisible = true;
      if (term === this.fields.values.term || !this.hasValidTerm) return;
      await this.populateSuggestions();
    },
    onOpenCategoryDropdown() {
      this.hideSuggestions();
    },
    onCloseCategoryDropdown() {
      this.suggestionsVisible = true;
    },
    hideSuggestions() {
      this.suggestionsVisible = false;
    },
    setSelectedCategory(value) {
      this.selectedCategory = value;
    },
    populateSuggestions() {
      this.isLoadingSuggestions = true;

      const { values } = this.fields;

      if (this.searchString !== values.term || this.searchCategory !== values.categoryPath) {
        this.suggestions = [];
        this.searchString = null;
        this.searchCategory = null;
      }

      if (this.debouncedSearch?.cancel) {
        this.debouncedSearch.cancel();
      }

      this.debouncedSearch(values);
      this.unselectSuggestion();
    },
    clearSuggestions() {
      this.suggestions = [];
    },
    openAndFocus() {
      this.$refs.primaryInput.focus();
    },
    selectAndFollowSuggestion(index) {
      this.selectSuggestion(index);
      this.followSuggestion();
    },
    followSuggestion() {
      navigateTo(this.selectedSuggestion.href);
      this.closeAndReset();
    },
    submitAndClose() {
      this.termChanged = false;
      if (this.selectedSuggestion) {
        this.followSuggestion();
      } else {
        this.submit();
      }
      if (controller) {
        controller.abort('Cancel previous search'); // cancel the suggestions request
      }
      if (this.debouncedSearch?.cancel) {
        this.debouncedSearch.cancel();
      }
      this.clearSuggestions();
      this.suggestions = [];
      this.searchString = null;
      this.searchCategory = null;
      this.isLoadingSuggestions = false;
    },
    closeAndReset() {
      this.termChanged = false;
      this.fields.values.term = '';
      this.closeSuggestions();
    },
    selectSuggestion(index) {
      this.selectedIndex = index;
    },
    selectPreviousSuggestion() {
      if (!this.hasSelectedSuggestion) {
        this.selectedIndex = this.lastSuggestionIndex;
      } else if (this.firstSuggestionSelected) {
        this.unselectSuggestion();
      } else {
        this.selectedIndex -= 1;
      }
    },
    selectNextSuggestion() {
      if (!this.hasSelectedSuggestion) {
        this.selectedIndex = 0;
      } else if (this.lastSuggestionSelected) {
        this.unselectSuggestion();
      } else {
        this.selectedIndex += 1;
      }
    },
    unselectSuggestion() {
      this.selectedIndex = -1;
    },
    closeSuggestions() {
      this.clearSuggestions();
      this.unselectSuggestion();
    },
    suggestionItemClasses(index) {
      return {
        'tsg-search-form__suggestions-list-item--selected': this.selectedIndex === index,
      };
    },
  },
};
</script>

<style lang="scss">
@import 'assets/base';

$container: '.tsg-search-form';

#{$container} {
  display: block;
  background-color: white;
  z-index: 1;
  width: 100%;
  height: 44px;
  border: 1px solid #d0d0d0;
  border-radius: 8px;

  &__content {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    height: 100%;
    width: 100%;
  }

  &__form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0;
    margin: 0;

    .dropdown {
      height: 100%;
    }
  }

  &__suggestions {
    position: relative;
    background-color: white;
    width: 100%;
    left: 0;
    border: 1px solid #d0d0d0;
    border-radius: 8px;
    margin-top: 12px;
  }

  &__suggestions-hint {
    padding: {
      top: $grid-small-gutter;
      bottom: $grid-small-gutter;
    }
  }

  &__suggestions-list {
    list-style: none;
    width: 100%;
  }

  &__suggestions-list-item {
    &:first-child {
      #{$container}__suggestions-list-link {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
    }

    &:last-child {
      #{$container}__suggestions-list-link {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }

  &__suggestions-list-link {
    padding: 10px;
    position: relative;
    display: block;

    @include mq($mq-medium) {
      padding-left: 54px;
    }

    &,
    &:hover,
    &:focus,
    &:visited,
    &:active {
      color: $color-font;
    }

    & em {
      font-weight: 700;
      font-style: normal;
      color: color(black);
    }
  }

  &__suggestions-list-item--selected #{$container}__suggestions-list-link {
    background-color: $color-link-bg;
  }

  &__icon {
    width: 30px;
    height: auto;
    fill: #6a6a6a;
    &--close {
      width: 22px;
      height: auto;
    }
  }

  &__input {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    background-color: transparent;
    border-radius: 0;
    border: 2px solid transparent;
    @include font-size(18px, 18px);
    height: 100%;

    &::placeholder {
      @include font-size(24px, 36px);
      color: #6a6a6a;
    }

    &:hover {
      border-color: transparent;
    }

    &:focus {
      border-color: $color-primary;
      background-color: transparent;
    }
  }

  &__btn {
    display: flex;
    border: none;
    background-color: transparent;
    height: 100%;
    cursor: pointer;
    padding: 0 12px;
    align-items: center;

    &:hover,
    &:focus,
    &:active {
      color: $color-primary;
      background-color: transparent;
    }
  }
}
</style>
