<template>
  <div
    class="tsg-back-button"
    :class="activeClass"
    @click="goBack"
  >
    <SvgIcon
      :image="arrowLeft"
      class="icon tsg-back-button__icon"
    />
    <span class="tsg-back-button__label"> Zurück </span>
  </div>
</template>

<script>
import arrowLeft from '@/assets/03-generic/images/arrow-left-menu.svg';

export default {
  name: 'TsgBackButton',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      arrowLeft,
    };
  },
  computed: {
    activeClass() {
      if (!this.active) return 'tsg-back-button--disabled';
      return '';
    },
  },
  methods: {
    goBack() {
      if (this.active) history.back(); //eslint-disable-line
    },
  },
};
</script>

<style lang="scss">
.tsg-back-button {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  &__icon {
    flex-shrink: 0;
    height: 24px;
  }
  &__label {
    display: none;
    margin-left: 12px;
    @media screen and (min-width: 1024px) {
      display: inline;
    }
  }
  &--disabled {
    opacity: 0.5;
    cursor: default;
  }
}
</style>
