<template>
  <div class="layout layout--default">
    <TsgPageHeader />
    <slot class="page" />
    <ScrollToTopButton />
    <TsgPageFooter />
    <DialogBackdrop />
    <client-only>
      <LoadingSpinner />
    </client-only>
    <CookieHint />
    <div id="teleport" />
    <template v-if="isDev">
      <StatusToolbarWrapper />
    </template>
  </div>
</template>

<script>
import CookieHint from '@/components/cookie-hint.vue';
import DialogBackdrop from '@/components/dialog-backdrop.vue';
import Layout from '@/mixins/layout';
import LoadingSpinner from '@/components/loading-spinner.vue';
import TsgPageFooter from '@/components/tsg-page-footer.vue';
import TsgPageHeader from '@/components/tsg-page-header.vue';
import ScrollToTopButton from '@/components/scroll-to-top-button.vue';

export default {
  name: 'DefaultLayout',
  components: {
    CookieHint,
    DialogBackdrop,
    LoadingSpinner,
    TsgPageHeader,
    TsgPageFooter,
    ScrollToTopButton,
    StatusToolbarWrapper: () => import('@/components/status-toolbar-wrapper.vue'),
  },
  mixins: [Layout],
  data() {
    return {
      isDev: !this.$config.public.mode.isProduction,
    };
  },
};
</script>

<style lang="scss">
.page {
  min-height: calc(100vh - 168px - 202px);
  @media screen and (min-width: 1024px) {
    min-height: calc(100vh - 168px - 238px);
  }
}
</style>
