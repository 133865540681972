<template>
  <div class="tsg-header-actions">
    <div class="grid grid--center">
      <div class="grid grid--items-center grid__item grid__item--1">
        <TsgBackButton
          :tabindex="backButtonTabIndex"
          :active="getRoute"
        />
      </div>
      <div class="grid grid--items-center grid__item--6 grid__item grid__item--overflow-visible">
        <TsgSearchForm
          ref="searchFormOnHomePage"
          @submit="search"
        />
      </div>
      <div class="grid grid--items-center grid__item grid__item--2">
        <CartIndicator
          :count="transitCartsQuantity"
          :label="'Übersicht'"
          :target="'/shop/warenkorb-virtuelles-regal'"
          :icon="listIcon"
          @click="fetchTransitCarts"
        />
      </div>
      <div class="grid grid--items-center grid__item grid__item--2">
        <CartIndicator
          :count="cartQuantity"
          :label="'Warenkorb'"
          :target="'/shop/warenkorb'"
          :icon="cartIcon"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { serializeFilterParams } from '@/lib/filter-params';
import cartIcon from '@/assets/03-generic/images/basket.svg';
import CartIndicator from '@/components/cart-indicator.vue';
import listIcon from '@/assets/03-generic/images/list.svg';
import TsgBackButton from '@/components/tsg-back-button.vue';
import TsgSearchForm from '@/components/tsg-search-form.vue';

const VS_TRANSIT_CART_FETCH_INTERVAL = 15 * 1000; // Every 15 seconds

export default {
  name: 'TsgHeaderActions',
  components: {
    TsgSearchForm,
    TsgBackButton,
    CartIndicator,
  },
  data() {
    return {
      cartIcon,
      listIcon,
      vrCartInterval: null,
    };
  },
  computed: {
    ...mapGetters('cart', ['cartQuantity', 'transitCartsQuantity']),
    getRoute() {
      return this.$route.path !== '/';
    },
    backButtonTabIndex() {
      if (this.$route.path === '/') return 0;
      return -1;
    },
  },
  mounted() {
    this.vrCartInterval = setInterval(this.fetchTransitCarts, VS_TRANSIT_CART_FETCH_INTERVAL);
  },
  unmounted() {
    if (this.vrCartInterval) {
      clearInterval(this.vrCartInterval);
    }
  },
  methods: {
    ...mapActions('cart', ['fetchTransitCarts']),
    search(params) {
      const opts = { search: params.term };
      if (params.categoryPath) {
        opts.filter = { productCategoryPaths: [params.categoryPath] };
      }
      const route = serializeFilterParams(opts);
      navigateTo(route);
    },
  },
};
</script>

<style lang="scss">
.tsg-header-actions {
  z-index: 10;
  position: relative;
  background-color: #ededed;
  height: 84px;
  display: flex;
  .tsg-back-button,
  .cart-indicator {
    &:hover {
      background-color: #ddd;
    }
  }
  .cart-indicator {
    &__icon {
      width: 35px;
      height: 30px;
      flex-shrink: 0;
    }
  }
}
</style>
