<template>
  <nuxt-link
    :to="target"
    active-class="cart-indicator--active"
    class="cart-indicator btn btn--transparent btn--borderless"
    tabindex="0"
  >
    <span
      v-if="label"
      class="text cart-indicator__label"
    >
      {{ label }}
    </span>
    <SvgIcon
      :class="iconClass"
      :image="icon"
      class="icon cart-indicator__icon"
    />
    <CountBadge
      v-if="count"
      :value="count"
      class="cart-indicator__count"
    />
  </nuxt-link>
</template>

<script>
import CountBadge from '@/components/count-badge.vue';

export default {
  name: 'CartIndicator',
  components: {
    CountBadge,
  },
  props: {
    iconClass: {
      type: String,
      default: null,
    },
    icon: {
      type: Object,
      default: null,
    },
    count: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '/shop/warenkorb',
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'assets/base';

$component: '.cart-indicator';

#{$component} {
  position: relative;
  text-decoration: none;
  width: 80px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @include mq($mq-large) {
    width: 165px;
  }

  &.btn--transparent {
    border-color: transparent;
  }

  &:active,
  &:visited,
  &:hover,
  &:focus {
    color: $color-font;
  }

  &__count {
    font-size: 15px;
    position: absolute;
    top: 0;
    right: 4px;
  }

  .secondary-navigation &--active {
    color: $color-primary;

    #{$component}__count {
      background-color: $color-primary;
      color: white;
    }
  }

  &__label {
    display: none;
    margin-right: 12px;
    @include mq($mq-large) {
      display: inline-block;
    }
  }
}
</style>
