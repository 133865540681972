<template>
  <div class="scroll-to-top-button show-from-extra-large">
    <transition name="scroll-to-top-button--fade">
      <button
        v-show="visible"
        class="scroll-to-top-button__button"
        @click="scrollTowardsTop"
      >
        <svg-icon
          :image="arrowScrollTop"
          class="icon scroll-to-top-button__icon"
        />
      </button>
    </transition>
  </div>
</template>

<script>
import arrowScrollTop from '@/assets/03-generic/images/arrow-scroll-up.svg';

export default {
  name: 'ScrollToTopButton',
  props: {
    showButtonBelow: {
      type: Number,
      default: 2000,
    },
  },
  data() {
    return {
      arrowScrollTop,
      visible: false,
    };
  },
  methods: {
    handleScrollEvent() {
      this.visible = window.pageYOffset > this.showButtonBelow;
    },
    scrollTowardsTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
  mounted() {
    this.handleScrollEvent();
    window.addEventListener('scroll', this.handleScrollEvent);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScrollEvent);
  },
};
</script>
<style lang="scss" scoped>
@import 'assets/base';

$m: '.scroll-to-top-button';

#{$m} {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  &--fade-enter-active,
  &--fade-leave-active {
    transition: opacity 0.7s;
  }

  &--fade-enter,
  &--fade-leave-to {
    opacity: 0;
  }

  &__container {
    overflow: visible;
    position: relative;
  }

  &__button {
    display: block;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    cursor: pointer;
    width: 38px;
    height: 38px;
    border: 1px solid color(grey, 12000);
    color: color(grey, 12000);
    padding: 5px 0 0 0;
    position: absolute;
    bottom: 50px;
    right: 10px;
    z-index: 50;
  }

  &__icon {
    width: 10px;
    height: 18px;
  }
}
</style>
