<template>
  <footer class="tsg-page-footer">
    <div class="tsg-page-footer__wrapper grid">
      <TsgFooterCorporate />
    </div>
  </footer>
</template>

<script>
import TsgFooterCorporate from '@/components/tsg-footer-corporate.vue';

const POSSIBLE_THEMES = ['standard', 'small'];

export default {
  name: 'TsgPageFooter',
  components: {
    TsgFooterCorporate,
  },
  props: {
    theme: {
      type: String,
      default: 'standard',
      validator: (value) => POSSIBLE_THEMES.includes(value),
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'assets/base';

.tsg-page-footer {
  background-color: color(grey, 10000);

  @include mq($mq-sm) {
    padding-top: $grid-base * 2;
  }
  @include mq($mq-md) {
    padding-top: $grid-base * 5;
  }

  .shop-finder {
    margin-top: $grid-medium-gutter;
  }
  .tsg-footer-accordion__image {
    &--av-logo {
      height: 65px;
    }
    &--left {
      width: 141px;
    }
  }
}
</style>
