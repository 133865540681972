export function assessLink(href) {
  if (!href.match(/^https?:\/\//)) {
    return { type: 'internal', to: { path: href } };
  }
  const host = href.match(/^https?:\/\/([^/]*)/)[1];
  const path = href.replace(/^https?:\/\/[^/]*/, '');
  if (path.startsWith('/berater')) {
    return { type: 'halfway', href: path };
  }
  if (host.includes('smarthome')) {
    return { type: 'internal', to: { path } };
  }
  return { type: 'external', href };
}

export default {
  assessLink,
};
