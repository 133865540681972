<template>
  <div class="grid__item grid__item--12">
    <branding :theme="'branding--dark branding--spaceless'" />
    <div class="grid grid--gutter-none tsg-footer-corporate">
      <div class="grid__item grid__item--12 grid__item--m-4 grid__item--l-3">
        <p class="tsg-footer-corporate__copyright">&copy; Telekom Deutschland GmbH</p>
      </div>
    </div>
  </div>
</template>

<script>
import Branding from '@/components/branding.vue';

export default {
  name: 'TsgFooterCorporate',
  components: {
    Branding,
  },
};
</script>

<style lang="scss" scoped>
@import 'assets/base';

.tsg-footer-corporate {
  border-top: 1px solid color(grey, 18000);
  color: color(grey, 18000);
  @include font-size(16px, 27px);
  text-align: center;
  padding-bottom: 3rem;
  display: flex;
  justify-content: space-between;

  @include mq($mq-medium) {
    @include font-size(16px, 16px);
    padding-top: 3rem;
    text-align: left;
  }

  &__list {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    &-item {
      list-style: none;
      width: 100%;
      text-align: center;

      @include mq($mq-medium) {
        width: auto;
        margin-left: $grid-medium-gutter;
        padding-bottom: 12px;
      }
    }
  }
  &__copyright {
    font-size: inherit;
    line-height: inherit;
    margin-top: 2rem;
    margin-bottom: 2rem;
    white-space: nowrap;

    @include mq($mq-medium) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  &__link {
    &:link,
    &:visited,
    &:hover,
    &:active {
      color: inherit;
      text-decoration: none;
    }
  }
}
</style>
